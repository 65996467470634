'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import { ModeToggle } from './mode-toggle';
import HistoryContainer from './history-container';
import LogoutButton from './LogoutButton';

interface ClientHeaderProps {
  varelaClass: string;
  session?: any;
}

const ClientHeader: React.FC<ClientHeaderProps> = ({ varelaClass, session }) => {
  const pathname = usePathname();

  return (
    <div className="flex justify-between items-center w-full">
      {/* Left-aligned navigation */}
      <div className="flex items-center space-x-6">
            <a href='/' className={`${varelaClass} text-[1.45rem] text-center mr-2`}>
              Fetchit
            </a>
      </div>
    </div>
  );
};

export default ClientHeader;
